import type { Link } from '~/types/link'

export function useLinks() {
  const getLink = (link: Link) => {
    switch (link.type.value) {
      case 'product':
        return {
          name: 'product-detail',
          params: { id: link.identifier, slug: link.slug },
        }
      case 'order':
        return {
          name: 'order-detail',
          params: { guid: link.identifier },
        }
      case 'product-list-flower':
        return {
          name: 'category-detail',
          params: { id: link.identifier, slug: link.slug, c: 'f' },
        }
      case 'product-list-occasions':
        return {
          name: 'category-detail',
          params: { id: link.identifier, slug: link.slug, c: 'o' },
        }
      case 'product-list-color':
        return {
          name: 'category-detail',
          params: { id: link.identifier, slug: link.slug, c: 'c' },
        }
      case 'article':
        return {
          name: 'article-detail',
          params: { id: link.identifier, slug: link.slug },
        }
      default:
        return undefined
    }
  }

  return { getLink }
}
